















import {
  ElUploadInternalFileDetail,
  ElUploadInternalRawFile,
  HttpRequestOptions,
} from 'element-ui/types/upload';
import Vue from 'vue';
import { checkFile } from '~/util/common';
import { uploadFile } from '~/util/oss';

export default Vue.extend({
  props: {
    accept: {
      type: String,
      default: 'image/*',
    },
    prop: {
      type: String,
      default: '',
    },
    checkSize: {
      type: Boolean,
      default: false,
    },
    value: {
      type: String,
      default: '',
    },
  },
  watch: {
    value: {
      handler(val, oldVal) {
        // console.log(val, oldVal);
        if (val !== oldVal) {
          this.imageUrl = val;
        }
      },
      immediate: true,
    },
  },
  data(): any {
    return {
      imageUrl: '',
    };
  },
  methods: {
    // 图片类型上传前
    async beforeImgUpload(file: ElUploadInternalRawFile) {
      const isJPG = /image/.test(file.type);
      const isLt2M = file.size / 1024 / 1024 < 2;
      let isSize = !this.checkSize;
      if (!isJPG) {
        this.$message.error('上传图片只能是图片格式!');
      }

      if (this.checkSize) {
        isSize = await checkFile(file);
        if (!isSize) {
          this.$message.error('上传图片尺寸不能小于600*800!');
        }
      }

      if (!isLt2M) {
        this.$message.error('上传图片大小不能超过 2MB!');
      }
      // 直接返回false, 无法阻止上传
      if (isJPG && isLt2M && isSize) {
        return Promise.resolve();
      } else {
        return Promise.reject(new Error());
      }
    },
    // 上传
    async handleUpload(option: HttpRequestOptions) {
      const file = option.file;
      return await uploadFile(file);
    },
    handleSuccess(
      res: any,
      file: ElUploadInternalFileDetail,
      fileList: ElUploadInternalFileDetail[]
    ) {
      this.imageUrl = URL.createObjectURL(file.raw);
      this.$emit('success', res, file, fileList, this.prop);
    },
  },
});
